import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import React from "react"
import CookieConsent from "react-cookie-consent"
import { LocaleContext } from "./layout"

const CookieBanner = () => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const location = useLocation()

  const handleOnAccept = () => {
    initializeAndTrack(location)
  }

  return (
    <CookieConsent
      location="bottom"
      buttonText={i18n.acceptCookies}
      enableDeclineButton="true"
      declineButtonText={i18n.declineCookies}
      cookieName="gatsby-gdpr-google-analytics"
      onAccept={handleOnAccept}
      flipButtons={true}
      buttonClasses="accept-cookies-button"
      declineButtonClasses="decline-cookies-button"
      overlay={true}
      overlayStyle={{ zIndex: 1031 }}
    >
      {i18n.cookiesMessage}
    </CookieConsent>
  )
}

export default CookieBanner
