import {
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React from "react"
import i18n from "../../config/i18n"
import CookieBanner from "./cookieBanner"
import Menu from "./menu"

const LocaleContext = React.createContext()

// Use the built-in Context API to make the "locale" available to every component in the tree
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!
const Layout = ({ location, title, children, pageContext }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  const locale = pageContext.locale
  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <Menu />
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <main>{children}</main>
        <footer className="footer py-4">
          <div className="container">
            <hr />
            <div className="row align-items-center">
              <div className="col-lg-6 text-lg-left">
                Copyright © {new Date().getFullYear()} Totem Culture
              </div>
              <div className="col-lg-6 text-lg-right my-3 my-lg-0">
                <a
                  href="https://twitter.com/TotemCulture"
                  className="btn btn-dark btn-social mx-2"
                  target="_blank"
                  rel="noreferrer"
                  title="Twitter"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  href="https://www.instagram.com/totem.culture/"
                  className="btn btn-dark btn-social mx-2"
                  target="_blank"
                  rel="noreferrer"
                  title="Instagram"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href="https://es.linkedin.com/in/carlaorrico"
                  className="btn btn-dark btn-social mx-2"
                  target="_blank"
                  rel="noreferrer"
                  title="LinkedIn"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <CookieBanner />
    </LocaleContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default Layout
export { LocaleContext }
