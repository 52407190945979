/**
 * Menu component
 */

import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React, { useState } from "react"
import { Nav, Navbar } from "react-bootstrap"

const Menu = () => {
  const data = useStaticQuery(menuQuery)
  const pages = data.menu.data.pages
  const [expanded, setExpanded] = useState(false);
  return (
    <Navbar expand="lg" bg="dark" variant="dark" fixed="top" expanded={expanded}>
      <div className="container">
        <Navbar.Brand>
          <Link to="/" itemProp="url" title="Home">
            <Img
              fixed={data.logo.childImageSharp.fixed}
              durationFadeIn={1}
              alt="Totem logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}  />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            {pages.map(page => {
              if (page.link.document.type === "category") {
                return (
                  <Nav.Link
                    as={Link}
                    to={"/categories" + page.link.document.url}
                    key={page.link.document.uid}
                    onClick={() => setTimeout(() => {setExpanded(false)}, 150)}
                  >
                    {page.link.document.data.name}
                  </Nav.Link>
                )
              } else {
                return (
                  <Nav.Link
                    as={Link}
                    to={page.link.document.url}
                    key={page.link.document.uid}
                    onClick={() => setTimeout(() => {setExpanded(false)}, 150)}
                  >
                    {page.link.document.data.title.text}
                  </Nav.Link>
                )
              }
            })}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Menu

export const menuQuery = graphql`
  query Menu {
    logo: file(absolutePath: { regex: "/logo.png/" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    menu: prismicMenu {
      data {
        pages {
          link {
            document {
              ... on PrismicCategory {
                uid
                type
                data {
                  name
                }
                url
              }
              ... on PrismicContact {
                uid
                type
                data {
                  title {
                    text
                  }
                }
                url
              }
            }
          }
        }
      }
    }
  }
`
