module.exports = {
  "es-es": {
    default: true,
    path: "es",
    locale: "es-es",
    siteLanguage: "es",
    ogLang: "es_ES",
    cookiesMessage:
      "Utilizamos cookies para medir el seguimiento de la página. Puedes aceptarlas o no.",
    acceptCookies: "Aceptar",
    declineCookies: "Rechazar",
    nextPage: "Siguiente página",
    previousPage: "Página anterior",
  },
  "en-gb": {
    default: false,
    path: "en",
    locale: "en-gb",
    siteLanguage: "en",
    ogLang: "en_US",
    cookiesMessage:
      "We use cookies to analyze site traffic. You can accept or reject them.",
    acceptCookies: "Acept",
    declineCookies: "Reject",
    nextPage: "Next page",
    previousPage: "Previous page",
  },
}
