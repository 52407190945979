// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import "typeface-raleway"
// normalize CSS across browsers
import "./src/normalize.css"

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css"

// custom CSS styles
import "./src/style.css"

// Locale provider wrapper
import LocaleWrap from "./wrapPageElement"

// Prismic preview wrapper
import PreviewWrap from "./wrapRootElement"

export const wrapPageElement = LocaleWrap
export const wrapRootElement = PreviewWrap
